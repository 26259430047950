import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import GeoJSON from 'ol/format/GeoJSON';
import {click, pointerMove, altKeyOnly} from 'ol/events/condition';
import {Draw, Modify, Snap, Select} from 'ol/interaction';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {OSM, Vector as VectorSource} from 'ol/source';
import {Circle as CircleStyle, Fill, Stroke, Style, Text, Icon} from 'ol/style';

import proj4 from 'proj4';

var raster = new TileLayer({
  source: new OSM()
});

// Syle fonction for polygons
function styleFunction(feature, resolution) {
	var s = new Style({
	  fill: new Fill({"color": feature.get('color')+"20"}),
	  stroke: new Stroke({
		  "color": feature.get('color')+"40",
		  "width": "3"
	  }),
	  text: new Text({
		text: feature.get('label'),
		scale: 1.8,
		stroke: new Stroke({"color": "white"}),
		"stroke-width": 3,
	    //fill: new Fill({"color": feature.get('color')+"F0"}),
		fill: new Fill({"color":"black"}),
		overflow: true
		})
	});

	return s;
}

var source = new VectorSource();
var vector = new VectorLayer({
  source: source,
  style: styleFunction
});

var iconFeature = new Feature({
  geometry: new Point([-870130, 3964998]), // Dar Bouazza, MA
  name: 'My position'
});

//source.addFeature(iconFeature);

var map = new Map({
  layers: [raster, vector],
  target: 'map',
  view: new View({
    center: iconFeature.getGeometry().getCoordinates(),
    zoom: 13
  })
});
var modify = new Modify({source: source});

var draw = new Draw({
    source: source,
    type: "Polygon",
  });

var snap = new Snap({source: source});
var editor = true;

function toggleEditor() {
  editor = !editor;
  if(editor) {
	map.addInteraction(modify);
	map.addInteraction(draw);
	map.addInteraction(snap);
	map.removeInteraction(selectPoly);
  }
  else {
	map.removeInteraction(modify);
	map.removeInteraction(draw);
	map.removeInteraction(snap);
	map.addInteraction(selectPoly);
  }
}

var selectPoly = new Select({});

selectPoly.on('select', function(e) {	
	if(e.deselected.length > 0) {
		var poly = e.deselected[0];
		poly.setId(document.getElementById("naming").value);
		poly.setProperties({
			color: document.getElementById("coloring").value,
			label: document.getElementById("label").value
		});

		document.getElementById("form-poly").hidden = true;
		document.getElementById("naming").value = "";
		document.getElementById("coloring").value = "#FFFFFF";
		document.getElementById("label").value = "";
	}

	if(e.target.getFeatures().getLength() > 0) {
		var poly = e.target.getFeatures().item(0);
		document.getElementById("form-poly").hidden = false;
		document.getElementById("naming").value = poly.getId();
		document.getElementById("coloring").value = poly.get("color");
		document.getElementById("label").value = poly.get("label");
	}
});

var gj = new GeoJSON();

document.getElementById('save').onclick = function() {
  var json = gj.writeFeatures(source.getFeatures());
  localStorage.setItem("poly", json);
}

document.onkeydown = keydown;

function keydown(evt) {
    if (!evt) evt = event;
    if (evt.key == "+") toggleEditor(editor);
    if (evt.key == "-") source.removeFeature(selectPoly.getFeatures().item(0));
}

toggleEditor();

iconFeature.setStyle(new Style({
  image: new Icon({
    src: require("./app/static/icon.png"),
	scale: .1,
	anchor: [0.5,1]
  })
}));

//if( localStorage.getItem("poly") ) {
//  var jsonPolygon = localStorage.getItem("poly");
var jsonPolygon = require("./app/static/data.json");
source.addFeatures(gj.readFeatures(jsonPolygon));

// Test if iconFeature(point) is in polygon
console.log(vector.getSource().getFeaturesAtCoordinate(iconFeature.getGeometry().getCoordinates()));
